<template>

  <el-container>

    <el-header height="220">



      <el-select @change="groupChange"   clearable v-model="queryInfo.group" placeholder="请选择集团"
                 style="margin-left: 5px">
        <el-option
          v-for="item in allGroup"
          :key="item.id"
          :label="item.dgroup"
          :value="item.dgroup">
        </el-option>
      </el-select>

      <el-select v-if="queryInfo.group !== ''"  @change="coalChange" clearable v-model="queryInfo.coal" placeholder="请选择煤矿"
                 style="margin-left: 5px">
        <el-option
          v-for="item in allCoal"
          :key="item.id"
          :label="item.coal"
          :value="item.coal">
        </el-option>
      </el-select>


      <el-select v-if="queryInfo.coal !== '' && queryInfo.group !== ''"  @change="deptChange" clearable v-model="queryInfo.dept" placeholder="请选择部门"
                 style="margin-left: 5px">
        <el-option
          v-for="item in allDept"
          :key="item.id"
          :label="item.dept"
          :value="item.dept">
        </el-option>
      </el-select>




      <el-select @change="oGradeChange" clearable v-model="queryInfo.oGrade" placeholder="请选择申报等级"
                 style="margin-left: 5px">
        <el-option
          v-for="item in oGradeType"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>



      <el-select @change="shenheChange" clearable v-model="queryInfo.shenhe" placeholder="请选择审核状态"
                 style="margin-left: 5px">
        <el-option
          v-for="item in shHeStatus"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>





      <el-row>
        <el-input v-model="queryInfo.relName" @blur="contentChange" placeholder="姓名"
                  style="margin-left: 5px;width: 217px"
                  prefix-icon="el-icon-search"></el-input>
        <el-input v-model="queryInfo.occupation" @blur="contentChange" placeholder="申报职业"
                  style="margin-left: 5px;width: 217px"
                  prefix-icon="el-icon-search"></el-input>
      </el-row>



      <el-row style="margin-top: 10px" v-model="operation">
        <el-button type="primary" icon="el-icon-plus" @click="addUserVisible = true">添加</el-button>


        <el-button type="success"  icon="el-icon-check" @click="operationChange('generatedExcel')">批量生成认定表</el-button>


        <el-button type="danger" icon="el-icon-delete" @click="open1">批量删除</el-button>


        <div align="right">
          <router-link to="/user" ><i class="el-icon-s-promotion">未完成报名人员</i></router-link>
        </div>

      </el-row>





    </el-header>

    <el-main>
      <!--操作的下拉框-->
      <el-select @change="operationChange" clearable v-if="selectionTable.length !== 0" v-model="operation"
                 :placeholder="'已选择' + selectionTable.length + '项'" style="margin-bottom: 25px;">

<!--        <el-option v-for="(item,index) in optionInfo" :key="index" :value="item.desc">
          <span style="float: left">{{ item.label }}</span>
          <span style="float: right; color: #ffffff; font-size: 13px">{{ item.desc }}</span>
        </el-option>-->

      </el-select>

      <el-table
        ref="userTable"
        highlight-current-row
        v-loading="loading"
        :border="true"
        :data="userInfo"
        tooltip-effect="dark"
        style="width: 100%;"

        @selection-change="handleTableSectionChange">

        <el-table-column align="center"
                         type="selection"
                         width="55">
        </el-table-column>

        <el-table-column align="center" prop="dgroup"
                         label="集团名称">
        </el-table-column>

        <el-table-column align="center" prop="coal"
                         label="煤矿名称">
        </el-table-column>

        <el-table-column align="center" prop="relName"
                         label="姓名">
          <template slot-scope="scope">
            <span class="quContent" @click="checkUse(scope.row.infoNo,scope.row.auditStatus,scope.row.remark)">{{ scope.row.relName }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="infoNo"
                         label="身份证">


        </el-table-column>

        <el-table-column align="center" prop="sex" width="80px"
                         label="性别">
          <template slot-scope="scope">
            <span v-if="scope.row.sex === 0">男</span>
            <span v-else-if="scope.row.sex === 1">女</span>

          </template>
        </el-table-column>


        <el-table-column align="center" prop="standard"
                         label="文化程度">
          <template slot-scope="scope">
            <span v-if="scope.row.standard === 1">小学</span>
            <span v-if="scope.row.standard === 2">初中</span>
            <span v-if="scope.row.standard === 3">职高</span>
            <span v-if="scope.row.standard === 4">高中</span>
            <span v-if="scope.row.standard === 5">技校</span>
            <span v-if="scope.row.standard === 6">高技</span>
            <span v-if="scope.row.standard === 7">高职</span>
            <span v-if="scope.row.standard === 8">中专</span>
            <span v-if="scope.row.standard === 9">大专</span>
            <span v-if="scope.row.standard === 10">大学</span>
            <span v-if="scope.row.standard === 11">本科</span>
            <span v-if="scope.row.standard === 12">硕士</span>
            <span v-if="scope.row.standard === 13">博士</span>
            <span v-if="scope.row.standard === 14">其他</span>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="occupation"
                         label="申报职业">
        </el-table-column>

        <el-table-column align="center" prop="grade"
                         label="等级">
        </el-table-column>

        <el-table-column align="center" prop="auditStatus"
                         label="审核状态" width="130">
          <template slot-scope="scope">


            <span v-if="scope.row.auditStatus === 0"  @click="open4(scope.row.remark)">

               <el-tooltip class="item" effect="dark" content="查看未通过原因" placement="top-start" >
              <el-alert class="custom-alert"   title="未通过" type="error" show-icon  :closable="false">
              </el-alert>
               </el-tooltip>
            </span>
            <span v-if="scope.row.auditStatus === 1">  <el-alert  :closable="false"
              title="未审核"
              type="info"
              show-icon>
            </el-alert >
            </span>
            <span v-if="scope.row.auditStatus === 2" >
              <el-alert  :closable="false"
              title="已通过"
              type="success"
              show-icon>
              </el-alert>
            </span>
          </template>
        </el-table-column>


        <el-table-column label="操作" align="center" fixed="right" width="230">
          <template slot-scope="scope">
            <el-button size="mini"
                       type="success"
                       @click="scrdb(scope.row.infoNo)">生成认定表</el-button>
            <el-tooltip class="item" effect="dark" content="修改信息" placement="top-start">
            <el-button size="mini" icon="el-icon-edit"
                       type="warning"
                       @click="updateUser(scope.row.infoNo)"   ></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除信息" placement="top-start">
            <el-button size="mini" icon="el-icon-delete"
                       type="danger"
                       @click="open(scope.row.infoNo)"></el-button>
            </el-tooltip>
          </template>

        </el-table-column>


      </el-table>

      <!--分页-->
      <el-pagination style="margin-top: 25px"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.pageNo"
                     :page-sizes="[10, 20, 30, 50]"
                     :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>

    </el-main>

    <el-dialog title="审核" :visible.sync="shenheTableVisible" width="50%"  center>
      <el-card>
        <el-form :model="remarkForm" ref="shenhe" :rules="shenheFormRules">

          <el-form-item prop="remark">
            <el-input type="textarea" :rows="6" placeholder="请输入审核意见" v-model="remarkForm.remark" style="margin-left: 5px" ></el-input>

          </el-form-item>

        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="shenheTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="shenhe">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="更新用户" :visible.sync="updateQuTableVisible" width="50%" center>
      <el-card>

          <el-form :model="updateUserForm" ref="updateUserForm" :rules="updateUserFormRules">

            <el-form-item label="归属集团" label-width="120px" prop="dgroup" >
              <el-select  v-model="updateUserForm.dgroup" placeholder="请选择" @change="groupAddChange">
                <el-option  v-for="item in allGroup" :key="item.dgroup"
                            :label="item.dgroup" :value="item.dgroup"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item  label="归属煤矿" label-width="120px" prop="coal">
              <el-select v-if="updateUserForm.dgroup != null"  v-model="updateUserForm.coal" placeholder="请选择" @change="coalAddChange">
                <el-option  v-for="item in allCoal" :key="item.coal"
                            :label="item.coal" :value="item.coal"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="归属部门" label-width="120px" prop="dept">
              <el-select  v-model="updateUserForm.dept" placeholder="请选择" @change="deptAddChange">
                <el-option v-if="updateUserForm.coal != null" v-for="item in allDept" :key="item.dept"
                           :label="item.dept" :value="item.dept"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="真实姓名" label-width="120px" prop="relName">
              <el-input v-model="updateUserForm.relName" style="margin-left: 5px" ></el-input>
            </el-form-item>

            <el-form-item label="身份证号" label-width="120px" prop="infoNo">
              <el-input v-model="updateUserForm.infoNo" style="margin-left: 5px" ></el-input>
            </el-form-item>


            <el-form-item label="邮箱" label-width="120px" prop="email">
              <el-input v-model="updateUserForm.email" style="margin-left: 5px"></el-input>
            </el-form-item>

            <el-form-item label="手机号" label-width="120px" prop="phonenumber">
              <el-input v-model="updateUserForm.phonenumber" style="margin-left: 5px"></el-input>
            </el-form-item>

            <el-form-item label="性别" label-width="120px" prop="sex">
              <el-select v-model="updateUserForm.sex" placeholder="请选择">
                <el-option :value="parseInt(0)" label="男" ></el-option>
                <el-option :value="parseInt(1)" label="女"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="通讯地址" label-width="120px" prop="address">
              <el-input v-model="updateUserForm.address" style="margin-left: 5px" type="textarea"></el-input>
            </el-form-item>

            <el-form-item label="个人图片" label-width="120px" >

              <el-upload
                :action="uploadImageUrl + '/util/updataImage'"
                :on-preview="uploadPreview"
                :on-remove="handleRemove"
                :headers="headers"
                :before-upload="beforeAvatarUpload"
                :limit="1"
                list-type="picture"
                :on-success="updateuploadImgSuccess"
                name="file">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
              </el-upload>
            </el-form-item>

            <el-form-item label="身份证图片" label-width="120px" >
              <el-upload
                :action="uploadImageUrl + '/util/updataImage'"
                :on-preview="uploadPreview"
                :on-remove="handleCardRemove"
                :headers="headers"
                :before-upload="beforeAvatarUpload"
                list-type="picture"
                :on-success="updateuploadCardImgSuccess"
                name="file">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
              </el-upload>
            </el-form-item>

            <el-form-item label="学信网图片" label-width="120px" >
              <el-upload
                :action="uploadImageUrl + '/util/updataImage'"
                :on-preview="uploadPreview"
                :on-remove="handleChisRemove"
                :headers="headers"
                :before-upload="beforeAvatarUpload"
                list-type="picture"
                :on-success="updateuploadChisImgSuccess"
                name="file">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
              </el-upload>
            </el-form-item>

            <el-form-item label="考生来源" label-width="120px" prop="sex">
              <el-select v-model="updateUserForm.comefrom" placeholder="请选择">
                <el-option :value="parseInt(1)" label="学校"></el-option>
                <el-option :value="parseInt(2)" label="企业"></el-option>
                <el-option :value="parseInt(3)" label="部队"></el-option>
                <el-option :value="parseInt(4)" label="社会"></el-option>
                <el-option :value="parseInt(5)" label="其他"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="出生日期" label-width="120px" prop="address" >
              <el-date-picker  value-format="yyyy年MM月dd日" format="yyyy年MM月dd日"     @change="dateChange"
                               v-model="updateUserForm.birthtime"
                               type="date"
                               placeholder="选择日期" >
              </el-date-picker >
            </el-form-item>

            <el-form-item label="文化程度" label-width="120px" prop="standard">
              <el-select v-model="updateUserForm.standard" placeholder="请选择">
                <el-option :value="parseInt(1)" label="小学"></el-option>
                <el-option :value="parseInt(2)" label="初中"></el-option>
                <el-option :value="parseInt(3)" label="职高"></el-option>
                <el-option :value="parseInt(4)" label="高中"></el-option>
                <el-option :value="parseInt(5)" label="技校"></el-option>
                <el-option :value="parseInt(6)" label="高技"></el-option>
                <el-option :value="parseInt(7)" label="高职"></el-option>
                <el-option :value="parseInt(8)" label="中专"></el-option>
                <el-option :value="parseInt(9)" label="大专"></el-option>
                <el-option :value="parseInt(10)" label="大学"></el-option>
                <el-option :value="parseInt(11)" label="本科"></el-option>
                <el-option :value="parseInt(12)" label="硕士"></el-option>
                <el-option :value="parseInt(13)" label="博士"></el-option>
                <el-option :value="parseInt(14)" label="其他"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="现职业等级(职称等级)" label-width="120px" prop="standard">
              <el-select v-model="updateUserForm.grade" placeholder="请选择">
                <el-option v-for="item in gradeType" :key="item.id" :lable="item.name" :value="item.name"></el-option>

              </el-select>
            </el-form-item>

            <el-form-item label="证书照片" label-width="120px" >
              <el-upload
                :action="uploadImageUrl + '/util/updataImage'"
                :on-preview="uploadPreview"
                :on-remove="handleCerRemove"
                :headers="headers"
                :before-upload="beforeAvatarUpload"
                list-type="picture"
                :on-success="updateuploadCerImgSuccess"
                name="file">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
              </el-upload>
            </el-form-item>

            <el-form-item label="申报职业" label-width="120px" prop="occupation">
<!--              <el-input v-model="updateUserForm.occupation" style="margin-left: 5px" ></el-input>-->
              <el-select    clearable v-model="updateUserForm.occupation" placeholder="请选择集团"
                            style="margin-left: 5px">
                <el-option
                  v-for="item in allOcc"
                  :key="item.id"
                  :label="item.occupation"
                  :value="item.occupation">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="申报等级" label-width="120px" prop="ograde">
              <el-select v-model="updateUserForm.ograde" placeholder="请选择">
                <el-option :value="parseInt(1)" label="一级"></el-option>
                <el-option :value="parseInt(2)" label="二级"></el-option>
                <el-option :value="parseInt(3)" label="三级"></el-option>
                <el-option :value="parseInt(4)" label="四级"></el-option>
                <el-option :value="parseInt(5)" label="五级"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="申报类型" label-width="120px" prop="type">
              <el-select v-model="updateUserForm.type" placeholder="请选择">
                <el-option :value="parseInt(1)" label="正考"></el-option>
                <el-option :value="parseInt(2)" label="重考"></el-option>
                <el-option :value="parseInt(3)" label="补考"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="考试科目" prop="subject" label-width="120px">
              <el-select multiple v-model="subject" placeholder="请选择" @change="subjectToString1">
                <el-option v-for="item in subjectType" :key="item.id"
                           :label="item.name" :value="item.name" ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="证件类型" label-width="120px" prop="mold">
              <el-select v-model="updateUserForm.mold" placeholder="请选择">
                <el-option :value="parseInt(1)" label="身份证"></el-option>
                <el-option :value="parseInt(2)" label="军官证"></el-option>
                <el-option :value="parseInt(3)" label="香港证件"></el-option>
                <el-option :value="parseInt(4)" label="澳门证件"></el-option>
                <el-option :value="parseInt(5)" label="台湾证件"></el-option>
                <el-option :value="parseInt(6)" label="外国护照"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="户籍所在地" label-width="120px" prop="infosite">
              <el-input v-model="updateUserForm.infosite" style="margin-left: 5px" type="textarea"></el-input>
            </el-form-item>

            <el-form-item label="户口性质" label-width="120px" prop="infoType">
              <el-select v-model="updateUserForm.infoType" placeholder="请选择">
                <el-option :value="parseInt(1)" label="本省城镇"></el-option>
                <el-option :value="parseInt(2)" label="本省农村"></el-option>
                <el-option :value="parseInt(3)" label="非本省城镇"></el-option>
                <el-option :value="parseInt(4)" label="非本省农村"></el-option>
                <el-option :value="parseInt(5)" label="台港澳人员"></el-option>
                <el-option :value="parseInt(6)" label="外籍人员"></el-option>
              </el-select>
            </el-form-item>



          </el-form>



      </el-card>

      <div slot="footer" class="dialog-footer">
        <el-button @click="updateChange">取 消</el-button>
        <el-button type="primary" @click="updateUserestion">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="新增用户" :visible.sync="addUserVisible" width="50%" @close="resetAddQuForm" center>

      <el-card>
        <el-form :model="addUserForm" ref="addUserForm" :rules="addUserFormRules">


          <el-form-item label="归属集团" label-width="120px" prop="dgroup" >
            <el-select  v-model="addUserForm.dgroup" placeholder="请选择" @change="groupAddChange">
              <el-option  v-for="item in allGroup" :key="item.dgroup"
                          :label="item.dgroup" :value="item.dgroup"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item  label="归属煤矿" label-width="120px" prop="coal">
            <el-select v-if="addUserForm.dgroup != null"  v-model="addUserForm.coal" placeholder="请选择" @change="coalAddChange">
              <el-option  v-for="item in allCoal" :key="item.coal"
                          :label="item.coal" :value="item.coal"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="归属部门" label-width="120px" prop="dept">
            <el-select  v-model="addUserForm.dept" placeholder="请选择" @change="deptAddChange">
              <el-option v-if="addUserForm.coal != null" v-for="item in allDept" :key="item.dept"
                         :label="item.dept" :value="item.dept"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="真实姓名" label-width="120px" prop="relName">
            <el-input v-model="addUserForm.relName" style="margin-left: 5px" ></el-input>
          </el-form-item>

          <el-form-item label="身份证号" label-width="120px" prop="infoNo">
            <el-input v-model="addUserForm.infoNo" style="margin-left: 5px"></el-input>
          </el-form-item>

          <el-form-item label="邮箱" label-width="120px" prop="email">
            <el-input v-model="addUserForm.email" style="margin-left: 5px"></el-input>
          </el-form-item>

          <el-form-item label="手机号" label-width="120px" prop="phonenumber">
            <el-input v-model="addUserForm.phonenumber" style="margin-left: 5px"></el-input>
          </el-form-item>

          <el-form-item label="性别" label-width="120px" prop="sex">
            <el-select v-model="addUserForm.sex" placeholder="请选择">
              <el-option :value="parseInt(0)" label="男" ></el-option>
              <el-option :value="parseInt(1)" label="女"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="通讯地址" label-width="120px" prop="address">
            <el-input v-model="addUserForm.address" style="margin-left: 5px" type="textarea"></el-input>
          </el-form-item>

          <el-form-item label="个人图片" label-width="120px" prop="avatar">
            <el-upload
              :action="uploadImageUrl + '/util/updataImage'"
              :on-preview="uploadPreview"
              :on-remove="handleRemove"
              :headers="headers"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
              :on-success="uploadImgSuccess"

              name="file">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
            </el-upload>
          </el-form-item>

          <el-form-item label="身份证图片" label-width="120px" prop="cardAvator">
            <el-upload
              :action="uploadImageUrl + '/util/updataImage'"
              :on-preview="uploadPreview"
              :on-remove="handleCardRemove"
              :headers="headers"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
              :on-success="uploadCardImgSuccess"
              name="file">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
            </el-upload>
          </el-form-item>

          <el-form-item label="学信网图片" label-width="120px" prop="chsiAvatar">
            <el-upload
              :action="uploadImageUrl + '/util/updataImage'"
              :on-preview="uploadPreview"
              :on-remove="handleChisRemove"
              :headers="headers"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
              :on-success="uploadChisImgSuccess"
              name="file">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
            </el-upload>
          </el-form-item>

          <el-form-item label="考生来源" label-width="120px" prop="sex">
            <el-select v-model="addUserForm.comefrom" placeholder="请选择">
              <el-option :value="parseInt(1)" label="学校"></el-option>
              <el-option :value="parseInt(2)" label="企业"></el-option>
              <el-option :value="parseInt(3)" label="部队"></el-option>
              <el-option :value="parseInt(4)" label="社会"></el-option>
              <el-option :value="parseInt(5)" label="其他"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="出生日期" label-width="120px" prop="address" >
            <el-date-picker  value-format="yyyy年MM月dd日" format="yyyy年MM月dd日"     @change="dateChange"
              v-model="addUserForm.birthtime"
              type="date"
              placeholder="选择日期" >
            </el-date-picker >
          </el-form-item>

          <el-form-item label="文化程度" label-width="120px" prop="standard">
            <el-select v-model="addUserForm.standard" placeholder="请选择">
              <el-option :value="parseInt(1)" label="小学"></el-option>
              <el-option :value="parseInt(2)" label="初中"></el-option>
              <el-option :value="parseInt(3)" label="职高"></el-option>
              <el-option :value="parseInt(4)" label="高中"></el-option>
              <el-option :value="parseInt(5)" label="技校"></el-option>
              <el-option :value="parseInt(6)" label="高技"></el-option>
              <el-option :value="parseInt(7)" label="高职"></el-option>
              <el-option :value="parseInt(8)" label="中专"></el-option>
              <el-option :value="parseInt(9)" label="大专"></el-option>
              <el-option :value="parseInt(10)" label="大学"></el-option>
              <el-option :value="parseInt(11)" label="本科"></el-option>
              <el-option :value="parseInt(12)" label="硕士"></el-option>
              <el-option :value="parseInt(13)" label="博士"></el-option>
              <el-option :value="parseInt(14)" label="其他"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="现职业等级(职称等级)" label-width="120px" prop="standard">
            <el-select v-model="addUserForm.grade" placeholder="请选择">
              <el-option v-for="item in gradeType" :key="item.id" :lable="item.name" :value="item.name"></el-option>

            </el-select>
          </el-form-item>

          <el-form-item label="证书照片" label-width="120px" prop="cerAvatar">
            <el-upload
              :action="uploadImageUrl + '/util/updataImage'"
              :on-preview="uploadPreview"
              :on-remove="handleCerRemove"
              :headers="headers"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
              :on-success="uploadCerImgSuccess"
              name="file">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
            </el-upload>
          </el-form-item>

          <el-form-item label="申报职业" label-width="120px" prop="occupation">
<!--            <el-input v-model="addUserForm.occupation" style="margin-left: 5px" ></el-input>-->
            <el-select    clearable v-model="addUserForm.occupation" placeholder="请选择集团"
                       style="margin-left: 5px">
              <el-option
                v-for="item in allOcc"
                :key="item.id"
                :label="item.occupation"
                :value="item.occupation">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="申报等级" label-width="120px" prop="oGrade">
            <el-select v-model="addUserForm.oGrade" placeholder="请选择">
              <el-option :value="parseInt(5)" label="五级"></el-option>
              <el-option :value="parseInt(4)" label="四级"></el-option>
              <el-option :value="parseInt(3)" label="三级"></el-option>
              <el-option :value="parseInt(2)" label="二级"></el-option>
              <el-option :value="parseInt(1)" label="一级"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="申报类型" label-width="120px" prop="type">
            <el-select v-model="addUserForm.type" placeholder="请选择">
              <el-option :value="parseInt(1)" label="正考"></el-option>
              <el-option :value="parseInt(2)" label="重考"></el-option>
              <el-option :value="parseInt(3)" label="补考"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考试科目" prop="subject" label-width="120px">
            <el-select multiple v-model="subject" placeholder="请选择" @change="subjectToString">
              <el-option v-for="item in subjectType" :key="item.id"
                         :label="item.name" :value="item.name" ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="证件类型" label-width="120px" prop="mold">
            <el-select v-model="addUserForm.mold" placeholder="请选择">
              <el-option :value="parseInt(1)" label="身份证"></el-option>
              <el-option :value="parseInt(2)" label="军官证"></el-option>
              <el-option :value="parseInt(3)" label="香港证件"></el-option>
              <el-option :value="parseInt(1)" label="澳门证件"></el-option>
              <el-option :value="parseInt(2)" label="台湾证件"></el-option>
              <el-option :value="parseInt(3)" label="外国护照"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="户籍所在地" label-width="120px" prop="infosite">
            <el-input v-model="addUserForm.infosite" style="margin-left: 5px" type="textarea"></el-input>
          </el-form-item>

          <el-form-item label="户口性质" label-width="120px" prop="infoType">
            <el-select v-model="addUserForm.infoType" placeholder="请选择">
              <el-option :value="parseInt(1)" label="本省城镇"></el-option>
              <el-option :value="parseInt(2)" label="本省农村"></el-option>
              <el-option :value="parseInt(3)" label="非本省城镇"></el-option>
              <el-option :value="parseInt(4)" label="非本省农村"></el-option>
              <el-option :value="parseInt(5)" label="台港澳人员"></el-option>
              <el-option :value="parseInt(6)" label="外籍人员"></el-option>
            </el-select>
          </el-form-item>



        </el-form>

      </el-card>

      <div slot="footer" class="dialog-footer">
        <el-button @click="chagnge" >取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="用户详情" :visible.sync="checkUserVisible" width="50%"  @close="resetShenQuForm"  center>

      <el-card>
        <el-form :model="checkUserForm" ref="checkUserForm" >

          <el-form-item label="归属集团" label-width="120px" prop="dgroup" >
            <el-select  v-model="checkUserForm.dgroup" placeholder="请选择" @change="groupAddChange" disabled >
              <el-option  v-for="item in allGroup" :key="item.dgroup"
                          :label="item.dgroup" :value="item.dgroup"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item  label="归属煤矿" label-width="120px" prop="coal">
            <el-select disabled  v-if="checkUserForm.dgroup != null"  v-model="checkUserForm.coal" placeholder="请选择" @change="coalAddChange">
              <el-option  v-for="item in allCoal" :key="item.coal"
                          :label="item.coal" :value="item.coal"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="归属部门" label-width="120px" prop="dept">
            <el-select disabled   v-model="checkUserForm.dept" placeholder="请选择" @change="deptAddChange">
              <el-option v-if="checkUserForm.coal != null " v-for="item in allDept" :key="item.dept"
                         :label="item.dept" :value="item.dept"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="真实姓名" label-width="120px" prop="relName" >
            <el-input v-model="checkUserForm.relName" style="margin-left: 5px"  :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="身份证号" label-width="120px" prop="infoNo">
            <el-input v-model="checkUserForm.infoNo" style="margin-left: 5px"  :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="邮箱" label-width="120px" prop="email">
            <el-input v-model="checkUserForm.email" style="margin-left: 5px"  :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="手机号" label-width="120px" prop="phonenumber">
            <el-input v-model="checkUserForm.phonenumber" style="margin-left: 5px"  :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="性别" label-width="120px" prop="sex">
            <el-select v-model="checkUserForm.sex" placeholder="请选择" disabled >
              <el-option :value="parseInt(0)" label="男" ></el-option>
              <el-option :value="parseInt(1)" label="女"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="通讯地址" label-width="120px" prop="address">
            <el-input v-model="checkUserForm.address" style="margin-left: 5px" type="textarea"  :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="个人图片" label-width="120px" prop="avatar">
<!--            <el-upload
              :action="uploadImageUrl + '/util/updataImage'"
              :on-preview="uploadPreview"
              :on-remove="handleRemove"
              :headers="headers"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
              :on-success="uploadImgSuccess"

              name="file">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
            </el-upload>-->

            <el-dialog :visible.sync="previewVisible">
              <img width="100%" :src="previewImage" alt="" />
            </el-dialog>
            <div class="uploaded-pictures">
              <template v-for="(picture, index) in checkUserForm.cardAvators">
                <img :key="index" :src="picture" alt="" @click="handlePicturePreview1(picture)" />
                <!--              <i class="el-icon-delete" @click="handlePictureRemove(index,picture)"></i>-->
              </template>
            </div>
          </el-form-item>




          <el-form-item label="身份证图片" label-width="120px" prop="cardAvator">
<!--            <el-upload
              :action="uploadImageUrl + '/util/updataImage'"
              :on-preview="uploadPreview"
              :on-remove="handleCardRemove"
              :headers="headers"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
              :on-success="uploadCardImgSuccess"
              name="file">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
            </el-upload>-->
            <el-dialog :visible.sync="previewVisible">
              <img width="100%" :src="previewImage" alt="" />
            </el-dialog>
            <div class="uploaded-pictures">
              <template v-for="(picture, index) in checkUserForm.chsiAvatars">
                <img :key="index" :src="picture" alt="" @click="handlePicturePreview1(picture)" />
                <!--              <i class="el-icon-delete" @click="handlePictureRemove(index,picture)"></i>-->
              </template>
            </div>
          </el-form-item>

          <el-form-item label="学信网图片" label-width="120px" prop="chsiAvatar">
<!--            <el-upload
              :action="uploadImageUrl + '/util/updataImage'"
              :on-preview="uploadPreview"
              :on-remove="handleChisRemove"
              :headers="headers"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
              :on-success="uploadChisImgSuccess"
              name="file">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
            </el-upload>-->
            <el-dialog :visible.sync="previewVisible">
              <img width="100%" :src="previewImage" alt="" />
            </el-dialog>
            <div class="uploaded-pictures">
              <template v-for="(picture, index) in checkUserForm.cerAvatars">
                <img :key="index" :src="picture" alt="" @click="handlePicturePreview1(picture)" />
                <!--              <i class="el-icon-delete" @click="handlePictureRemove(index,picture)"></i>-->
              </template>
            </div>
          </el-form-item>

          <el-form-item label="考生来源" label-width="120px" prop="sex">
            <el-select v-model="checkUserForm.comefrom" placeholder="请选择" disabled >
              <el-option :value="parseInt(1)" label="学校"></el-option>
              <el-option :value="parseInt(2)" label="企业"></el-option>
              <el-option :value="parseInt(3)" label="部队"></el-option>
              <el-option :value="parseInt(4)" label="社会"></el-option>
              <el-option :value="parseInt(5)" label="其他"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="出生日期" label-width="120px" prop="address" >
            <el-date-picker  value-format="yyyy年MM月dd日" format="yyyy年MM月dd日"     @change="dateChange" disabled
                             v-model="checkUserForm.birthtime"
                             type="date"
                             placeholder="选择日期" >
            </el-date-picker >
          </el-form-item>

          <el-form-item label="文化程度" label-width="120px" prop="standard">
            <el-select v-model="checkUserForm.standard" placeholder="请选择" disabled >
              <el-option :value="parseInt(1)" label="小学"></el-option>
              <el-option :value="parseInt(2)" label="初中"></el-option>
              <el-option :value="parseInt(3)" label="职高"></el-option>
              <el-option :value="parseInt(4)" label="高中"></el-option>
              <el-option :value="parseInt(5)" label="技校"></el-option>
              <el-option :value="parseInt(6)" label="高技"></el-option>
              <el-option :value="parseInt(7)" label="高职"></el-option>
              <el-option :value="parseInt(8)" label="中专"></el-option>
              <el-option :value="parseInt(9)" label="大专"></el-option>
              <el-option :value="parseInt(10)" label="大学"></el-option>
              <el-option :value="parseInt(11)" label="本科"></el-option>
              <el-option :value="parseInt(12)" label="硕士"></el-option>
              <el-option :value="parseInt(13)" label="博士"></el-option>
              <el-option :value="parseInt(14)" label="其他"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="现职业等级(职称等级)" label-width="120px" prop="standard">
            <el-select v-model="checkUserForm.grade" placeholder="请选择" disabled >
              <el-option v-for="item in gradeType" :key="item.id" :lable="item.name" :value="item.name"></el-option>

            </el-select>
          </el-form-item>

          <el-form-item label="证书照片" label-width="120px" prop="cerAvatar">
<!--            <el-upload
              :action="uploadImageUrl + '/util/updataImage'"
              :on-preview="uploadPreview"
              :on-remove="handleCerRemove"
              :headers="headers"
              :before-upload="beforeAvatarUpload"
              list-type="picture"
              :on-success="uploadCerImgSuccess"
              name="file">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过10M</div>
            </el-upload>-->
            <el-dialog :visible.sync="previewVisible">
              <img width="100%" :src="previewImage" alt="" />
            </el-dialog>
            <div class="uploaded-pictures">
              <template v-for="(picture, index) in checkUserForm.avatars">
                <img :key="index" :src="picture" alt="" @click="handlePicturePreview1(picture)" />
                <!--              <i class="el-icon-delete" @click="handlePictureRemove(index,picture)"></i>-->
              </template>
            </div>
          </el-form-item>

          <el-form-item label="申报职业" label-width="120px" prop="occupation">
            <el-input v-model="checkUserForm.occupation" style="margin-left: 5px" :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="申报等级" label-width="120px" prop="oGrade">
            <el-select v-model="checkUserForm.oGrade" placeholder="请选择" disabled >
              <el-option :value="parseInt(5)" label="五级"></el-option>
              <el-option :value="parseInt(4)" label="四级"></el-option>
              <el-option :value="parseInt(3)" label="三级"></el-option>
              <el-option :value="parseInt(2)" label="二级"></el-option>
              <el-option :value="parseInt(1)" label="一级"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="申报类型" label-width="120px" prop="type">
            <el-select v-model="checkUserForm.type" placeholder="请选择" disabled >
              <el-option :value="parseInt(1)" label="正考"></el-option>
              <el-option :value="parseInt(2)" label="重考"></el-option>
              <el-option :value="parseInt(3)" label="补考"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="考试科目" prop="subject" label-width="120px">
            <el-select multiple v-model="subject" placeholder="请选择" @change="subjectToString" disabled >
              <el-option v-for="item in subjectType" :key="item.id"
                         :label="item.name" :value="item.name" ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="证件类型" label-width="120px" prop="mold">
            <el-select v-model="checkUserForm.mold" placeholder="请选择" disabled >
              <el-option :value="parseInt(1)" label="身份证"></el-option>
              <el-option :value="parseInt(2)" label="军官证"></el-option>
              <el-option :value="parseInt(3)" label="香港证件"></el-option>
              <el-option :value="parseInt(1)" label="澳门证件"></el-option>
              <el-option :value="parseInt(2)" label="台湾证件"></el-option>
              <el-option :value="parseInt(3)" label="外国护照"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="户籍所在地" label-width="120px" prop="infosite">
            <el-input v-model="checkUserForm.infosite" style="margin-left: 5px" type="textarea"  :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="户口性质" label-width="120px" prop="infoType">
            <el-select v-model="checkUserForm.infoType" placeholder="请选择" disabled >
              <el-option :value="parseInt(1)" label="本省城镇"></el-option>
              <el-option :value="parseInt(2)" label="本省农村"></el-option>
              <el-option :value="parseInt(3)" label="非本省城镇"></el-option>
              <el-option :value="parseInt(4)" label="非本省农村"></el-option>
              <el-option :value="parseInt(5)" label="台港澳人员"></el-option>
              <el-option :value="parseInt(6)" label="外籍人员"></el-option>
            </el-select>
          </el-form-item>


          <el-form-item label="审核状态" label-width="120px" prop="auditStatus">
            <el-input v-model="checkUserForm.auditStatus"  style="margin-left: 5px"   :disabled="true"></el-input>
          </el-form-item>

          <el-form-item label="未通过原因" label-width="120px" prop="remark" v-if="checkUserForm.auditStatus === '未通过'">
            <el-input v-model="checkUserForm.remark" style="margin-left: 5px" type="textarea"  :disabled="true"></el-input>
          </el-form-item>


        </el-form>

      </el-card>

      <div slot="footer" class="dialog-footer">
        <el-button @click="butongguo(checkUserForm.remark)" >不 通 过</el-button>
        <el-button type="primary" @click="shenheT">通 过</el-button>
      </div>
    </el-dialog>



  </el-container>

</template>



<script>
import questionBank from '@/api/questionBank'
import question from '@/api/question'
import utils from '@/utils/utils'
import { generateSign } from '@/utils/sign'



export default {
  name: 'AllUserManage',


  data () {
    return {
      uploadImageUrl: process.env.VUE_APP_UPLOAD_IMAGE_URL,
      //查询用户的参数
      queryInfo: {
        //题目类型下拉款所选的内容
        'oGrade':'',
        'occupation':'',
        'shenhe':'',
        'relName': '',
        'group': '',
        'coal':'',
        'dept':'',
        'pageNo': 1,
        'pageSize': 10
      },
      previewImage:'',
      //题目类型
      subjectType:[
        {
          id: 1,
          name: '理论',
        },
        {
          id: 2,
          name: '技能',
        },
        {
          id: 3,
          name: '综合评审',
        },
        {
          id: 4,
          name: '外语',
        }
      ],

      gradeType:[
        {
          id: 1,
          name: '初级职称',
        },
        {
          id: 2,
          name: '中级职称',
        },
        {
          id: 3,
          name: '高级职称',
        },
        {
          id: 4,
          name: '无等级',
        },
        {
          id: 5,
          name: '五级/初级',
        },
        {
          id: 6,
          name: '四级',
        },
        {
          id: 7,
          name: '三级',
        },
        {
          id: 8,
          name: '二级',
        }
      ],
      shHeStatus:[
        {
          id: 0,
          name: '未通过',
        },
        {
          id: 1,
          name: '未审核',
        },
        {
          id: 2,
          name: '已通过',
        },
      ],
      oGradeType:[
        {
          id: 1,
          name: '一级',
        },
        {
          id: 2,
          name: '二级',
        },
        {
          id: 3,
          name: '三级',
        },
        {
          id: 4,
          name: '四级',
        },
        {
          id: 5,
          name: '五级',
        }
      ],
      group:{
        'dgroup':'',

     },
      coal:{
        'dgroup':'',
        'coal':'',

      },
      //题库信息
      allGroup: [],
      allOcc:[],
      allCoal:[],
      allDept:[],
      //题目信息
      userInfo: [],
      //题目信息表格是否加载
      loading: true,
      deleteUser:false,
      previewVisible:false,
      //表格被选中的所有行
      selectionTable: [],
      //表格行被选中后的所有操作方式的数据
      optionInfo: [
        {
          'label': '删除',
          'desc': 'delete'
        },
        {
          'label': '生成认定表',
          'desc': 'generatedExcel'
        }

      ],
      checkedSubject:[
        {
          id:1,
          name:"理论"
        },
        {
          id:2,
          name:"技能"
        },
        {
          id:3,
          name:"综合评审"
        },
        {
          id:4,
          name:"外语"
        },

      ],
      //表格行被选中后的数据
      operation: '',
      //题目总数
      total: 0,
      //是否显示添加user对话框
      addUserVisible: false,
      checkUserVisible:false,
      //是否显示移除题库对话框
      removeTableVisible: false,
      //是否显示添加题目的对话框
      addQuTableVisible: false,

      //添加题库的表单信息
      addForm: {
        bankId: ''
      },
      removeForm: {
        bankId: ''
      },
      //添加题库表单的验证
      addFormRules: {
        bankId: [
          {
            required: true,
            message: '请选择需要添加进的题库',
            trigger: 'blur'
          }
        ]
      },
      //移除题库表单的验证
      removeFormRules: {
        bankId: [
          {
            required: true,
            message: '请选择需要移除的题库',
            trigger: 'blur'
          }
        ]
      },
      subject:[],
      avatar:[],
      cardAvator:[],
      chsiAvatar:[],
      cerAvatar:[],
      //添加题目的表单信息
      addUserForm: {
       infoNo:'',
       relName: '',
       email:'',
       phonenumber:'',
       sex:0,
       address:'',
       avatar:'',
       cardAvator:'',
       chsiAvatar:'',
       comefrom:1,
       birthtime:'',
       standard:1,
       grade:'',
       cerAvatar:'',
       occupation:'',
       oGrade:1,
       type:1,
       subject:'',
       mold:1,
       infosite:'',
       infoType:1,
       dgroup:'',
       coal:'',
       dept:'',

      },
      checkUserForm: {
        avatars:[],
        infoNo:'',
        relName: '',
        email:'',
        phonenumber:'',
        sex:0,
        address:'',
        avatar:'',
        cardAvators:[],
        cardAvator:'',
        chsiAvatar:'',
        chsiAvatars:[],
        comefrom:1,
        birthtime:'',
        standard:1,
        grade:'',
        cerAvatar:'',
        cerAvatars:[],
        occupation:'',
        oGrade:1,
        type:1,
        subject:'',
        mold:1,
        infosite:'',
        infoType:1,
        dgroup:'',
        coal:'',
        dept:'',
        auditStatus:'',
        remark:'',

      },

      remarkForm:{
        infoNo:'',
        remark:''
      },
      updateUserForm:{
        jiuinfoNo:'',
        infoNo:'',
        relName: '',
        email:'',
        phonenumber:'',
        sex:0,
        address:'',
        avatar:'',
        cardAvator:'',
        chsiAvatar:'',
        comefrom:1,
        birthtime:'',
        standard:1,
        grade:'',
        cerAvatar:'',
        occupation:'',
        ograde:1,
        type:1,
        subject:'',
        mold:1,
        infosite:'',
        infoType:1,
        dgroup:'',
        coal:'',
        dept:'',
      },
      //添加题目表单的验证规则
      addUserFormRules: {
        relName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        infoNo: [
          {
            required: true,
            min: 18,
            message: '身份证不能小于18个位',
            trigger: 'blur'
          }
        ],

        phonenumber:[{ required: true,message: '请输入手机号',min:11}],
        sex:[{ required: true}],
        address:[{ required: true,message: '请填写通讯地址',}],
        avatar:[{ required: true}],
        chsiAvatar:[{ required: true}],
        cerAvatar:[{ required: true}],
        comefrom:[{ required: true}],
        birthtime:[{ required: true}],
        standard:[{ required: true}],
        grade:[{ required: true}],
        cardAvator:[{ required: true}],
        occupation:[{ required: true,message: '请填写申报职业'}],
        oGrade:[{ required: true}],
        type:[{ required: true}],
        subject:[{ required: true}],
        mold:[{ required: true}],
        infosite:[{ required: true}],
        infoType:[{ required: true}],
        dgroup:[{ required: true,message: '请选择归属集团',}],
        coal:[{ required: true,message: '请选择归属煤矿',}],
        dept:[{ required: true,message: '请选择归属部门',}],



      },
      shenheFormRules:{
         remark: [{ required: true,message: '请填写原因',}
        ]

      },

      //更新题目表单的验证规则
      updateUserFormRules: {
        relName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        infoNo: [
          {
            required: true,
            min: 18,
            message: '身份证不能小于18个位',
            trigger: 'blur'
          }
        ],

        phonenumber:[{ required: true,message: '请输入手机号',min:11}],
        sex:[{ required: true}],
        address:[{ required: true,message: '请填写通讯地址',}],
        avatar:[{ required: true}],
        chsiAvatar:[{ required: true}],
        cerAvatar:[{ required: true}],
        comefrom:[{ required: true}],
        birthtime:[{ required: true}],
        standard:[{ required: true}],
        grade:[{ required: true}],
        cardAvator:[{ required: true}],
        occupation:[{ required: true,message: '请填写申报职业'}],
        oGrade:[{ required: true}],
        type:[{ required: true}],
        subject:[{ required: true}],
        mold:[{ required: true}],
        infosite:[{ required: true}],
        infoType:[{ required: true}],
        dgroup:[{ required: true,message: '请选择归属集团',}],
        coal:[{ required: true,message: '请选择归属煤矿',}],
        dept:[{ required: true,message: '请选择归属部门',}],
      },

      shenheTableVisible:false,

      //是否显示更新题目的对话框
      updateQuTableVisible: false,
    }
  },
  created () {
    this.getUserInfo()
    this.getAllGroup()
    this.getOccInfo()


  },
  methods: {

    getOccInfo() {
      questionBank.getOccInfo(this.queryInfo).then((resp) => {
        if (resp.code === 200) {

          this.allOcc = resp.data.data

        }
      })
    },

    getUserInfo() {
      questionBank.getUserInfo(this.queryInfo).then((resp) => {
        if (resp.code === 200) {
          this.userInfo = resp.data.data
          this.total = resp.data.total
          this.loading = false

        }
      })
    },
    getAllGroup() {
      questionBank.getAllGroup().then((resp) => {
        if (resp.code === 200) {
          this.allGroup = resp.data
        }
      })
    },
    getAllCoal() {

      questionBank.getAllCoal(this.group).then((resp) => {
        if (resp.code === 200) {
          this.allCoal = resp.data
        }
      })

    },
    getAllDept() {
      questionBank.getAllDept(this.coal).then((resp) => {
        if (resp.code === 200) {
          this.allDept = resp.data
        }

      })
    },
    yuanyin(val){
      //console.log(val)
    },

    handlePicturePreview1(file){
      this.previewImage = file
      this.previewVisible = true;
    },
    updateUser(infoNo) {

      question.queryUserByNo(infoNo).then((resp) => {
        if (resp.code === 200) {

          this.updateUserForm = resp.data
         // console.log(this.updateUserForm)
          this.subject = resp.data.subject.split(",")
          this.updateUserForm.jiuinfoNo = infoNo
          //console.log(this.updateUserForm.jiuinfoNo)
        }
        this.updateQuTableVisible = true
      })
    },
    coalAddChange(val) {

      this.coal.dgroup = this.group.dgroup
      this.coal.coal = val
      if (val !== null) {
        this.getAllDept()
      }
    },
    deptAddChange(val) {


    },

    dateChange(date) {
     // console.log(date)

      this.addUserForm.birthtime = date + '';
    },
    subjectToString(val) {
      const subString = this.subject.join(',');
      this.addUserForm.subject = subString;

    },
    subjectToString1(val) {
      const subString = this.subject.join(',');
      this.updateUserForm.subject = subString;
     // console.log(this.updateUserForm.subject)

    },

    groupAddChange(val) {
    //  console.log(val)

      this.group.dgroup = val
      if (val !== null) {
        this.getAllCoal()
      }
    },
    groupChange(val) {
      this.queryInfo.coal=""
      this.queryInfo.dept=""

      this.queryInfo.group = val
      this.getUserInfo()
      this.group.dgroup = this.queryInfo.group
      if (this.queryInfo.group !== null) {
        this.getAllCoal()
      }


    },
    coalChange(val) {
      this.queryInfo.dept=""

      this.queryInfo.coal = val
      this.getUserInfo()
      this.coal.dgroup = this.queryInfo.group
      this.coal.coal = this.queryInfo.coal
      if(this.queryInfo.coal != null && this.queryInfo.group !== null){
        this.getAllDept()
      }

    },
    deptChange(val){
      this.getUserInfo()
    },
    standardChange(val) {
      this.queryInfo.standard = val
      this.getUserInfo()
    },
    oGradeChange(val) {
      this.queryInfo.oGrade = val
      this.getUserInfo()
    },
    shenheChange(val){
      this.queryInfo.shenhe = val
      this.getUserInfo()
    },
    //处理表格被选中
    handleTableSectionChange(val) {
      this.selectionTable = val
    },
    contentChange() {
      //发送查询题目总数的请求
      this.getUserInfo()
    },
    //处理操作选择框的变化
    open(val) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        question.deleteUser({'userInfoNos': val}).then(resp => {
          if (resp.code === 200) {
            this.deleteUser = false
            this.getUserInfo()
          }
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    open1(val) {

      if(this.selectionTable.length === 0){

        this.$message.error('请选择用户')
        return false
      }

      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.operationChange('delete')
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    operationChange(val) {
      if(this.selectionTable.length === 0){

        this.$message.error('请选择用户')
        return false
      }
      //清空上一次的选择
      this.operation = ''

      let infoNoIds = []
      if (val === 'delete') {
        this.selectionTable.map(item => {
          infoNoIds.push(item.infoNo)
        })
        //发起删除请求
        question.deleteUser({'userInfoNos': infoNoIds.join(',')}).then(resp => {
          if (resp.code === 200) {
            this.$notify({
              title: 'Tips',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.getUserInfo()
          }
        })
      } else if (val === 'generatedExcel') {
        this.selectionTable.map(item => {
          infoNoIds.push(item.infoNo)
        })

        question.generatedExcel({'infos': infoNoIds.join(',')}).then(response => {

            let blob = new Blob([response], { type: "application/zip" });
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            link.download = "pic.zip"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url); // 释放内存



        },
          (err)=>{
            console.log(err);
          })
      }
    },
    open4(val) {
      this.$alert(val, '原因', {
        confirmButtonText: '确定',
        callback: action => {

        }
      });
    },
    scrdb(val){
      //console.log(val)
      question.generatedExcel({infos:val}).then(response => {
          let blob = new Blob([response], { type: "application/zip" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          link.download = "pic.zip"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url); // 释放内存



        },
        (err)=>{
          console.log(err);
        })

    },

    //分页页面大小改变
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.getUserInfo()
    },
    //分页插件的页数
    handleCurrentChange(val) {
      this.queryInfo.pageNo = val
      this.getUserInfo()
    },
    //表单信息重置
    resetAddForm() {
      //清空表格数据
      this.$refs['addForm'].resetFields()
    },
    resetRemoveForm() {
      //清空表格数据
      this.$refs['removeForm'].resetFields()
    },
    resetAddQuForm() {
      this.$refs['addUserForm'].resetFields()
    },
    resetShenQuForm(){
      this.$refs['checkUserForm'].resetFields()
    },
    //提交加入题库的表单信息
    addBank() {
      utils.validFormAndInvoke(this.$refs['addForm'], () => {
        let questionIds = []
        let banks = this.addForm.bankId
        //将表格选中的数据中的问题id加入进去
        this.selectionTable.map(item => {
          questionIds.push(item.id)
        })
        questionBank.addBankQuestion({
          'questionIds': questionIds.join(','),
          'banks': banks.join(',')
        }).then((resp) => {
          if (resp.code === 200) {
            this.getQuestionInfo()
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'success',
              duration: 2000
            })
          }
          this.addTableVisible = false
        })
      }, '请选择需要加入进的题库')
    },
    //提交移除题库的表单信息
    removeBank() {
      utils.validFormAndInvoke(this.$refs['removeForm'], () => {
        let questionIds = []
        let banks = this.removeForm.bankId
        //将表格选中的数据中的问题id加入进去
        this.selectionTable.map(item => {
          questionIds.push(item.id)
        })
        //发起移除请求
        questionBank.removeBankQuestion({
          'questionIds': questionIds.join(','),
          'banks': banks.join(',')
        }).then((resp) => {
          if (resp.code === 200) {
            this.getQuestionInfo()
            this.$notify({
              title: 'Tips',
              message: resp.message,
              type: 'success',
              duration: 2000
            })
          }
          this.removeTableVisible = false
        })
      }, '请选择需要移除的题库')
    },

    checkUse(infoNo,auditStatus,remark){
      question.queryUserByNo(infoNo).then((resp) => {
        if (resp.code === 200) {

          this.checkUserForm = resp.data
          // console.log(this.updateUserForm)
          this.subject = resp.data.subject.split(",")
          this.checkUserForm.avatars = resp.data.avatar.split(",")
          this.checkUserForm.cerAvatars = resp.data.cerAvatar.split(",")
          this.checkUserForm.chsiAvatars = resp.data.chsiAvatar.split(",")
          this.checkUserForm.cardAvators = resp.data.cardAvator.split(",")
          if(auditStatus === 0){
            this.checkUserForm.auditStatus = '未通过'
            this.checkUserForm.remark = remark
          }
          if(auditStatus === 1){
            this.checkUserForm.auditStatus = '未审核'
          }
          if(auditStatus === 2){
            this.checkUserForm.auditStatus = '已通过'
          }


          //console.log(this.checkUserForm)

        }
        this.checkUserVisible = true
      })
    },
    //新增题目中的新增答案填写框
    addAnswer() {
      this.addQuForm.answer.push({
        id: this.addQuForm.answer.length,
        isTrue: false,
        answer: '',
        images: [],
        analysis: ''
      })
    },
    //更新时新增题目中的新增答案填写框
    addUpdateAnswer() {
      this.updateQuForm.answer.push({
        id: this.updateQuForm.answer.length,
        isTrue: false,
        answer: '',
        images: [],
        analysis: ''
      })
    },

    open2(val) {
      this.$confirm('该学员信息审核是否通过？', '审核状态', {
        distinguishCancelAndClose: true,
        confirmButtonText: '通过',
        cancelButtonText: '未通过'
      })
        .then(() => {
          this.shenhe()
          this.$message({
            type: 'success',
            message: '已通过'
          });
        })
        .catch(action => {

          this.chakan(val)
          this.$message({
            type: 'error',
            message: action === 'cancel'
              ? '审核未通过'
              : '已取消审核'
          })
        });
    },
    //新增题目中的删除答案填写框
    delAnswer(id) {//当前答案的id
      this.addQuForm.answer.map((item, index) => {
        if (item.id === id) this.addQuForm.answer.splice(index, 1)
      })
    },
    //新增题目中的删除答案填写框
    delUpdateAnswer(id) {//当前答案的id
      this.updateQuForm.answer.map((item, index) => {
        if (item.id === id) this.updateQuForm.answer.splice(index, 1)
      })
    },

    //选择正确答案的按钮变化事件
    checkAnswer(checked, id) {
      if (checked) {
        if (this.addQuForm.questionType === 1 || this.addQuForm.questionType === 3) {//单选或者判断
          //当前已经有一个正确的选择了
          this.addQuForm.answer.map(item => {
            item.isTrue = false
          })
          this.addQuForm.answer.map(item => {
            if (item.id === id) item.isTrue = true
          })
        } else {//多选 可以有多个答案
          this.addQuForm.answer.map(item => {
            if (item.id === id) item.isTrue = true
          })
        }
      } else {
        this.addQuForm.answer.map(item => {
          if (item.id === id) item.isTrue = false
        })
      }
    },
    //更新时选择正确答案的按钮变化事件
    checkUpdateAnswer(checked, id) {
      if (checked) {
        if (this.updateQuForm.questionType === 1 || this.updateQuForm.questionType === 3) {//单选或者判断
          //当前已经有一个正确的选择了
          this.updateQuForm.answer.map(item => {
            item.isTrue = false
          })
          this.updateQuForm.answer.map(item => {
            if (item.id === id) item.isTrue = true
          })
        } else {//多选 可以有多个答案
          this.updateQuForm.answer.map(item => {
            if (item.id === id) item.isTrue = true
          })
        }
      } else {
        this.updateQuForm.answer.map(item => {
          if (item.id === id) item.isTrue = false
        })
      }
    },
    //新增题目上传后 点击图片的回显
    uploadPreview(file) {
      this.backShowImgUrl = file.response.data
      this.backShowImgVisible = true
    },
    //新增题目中的上传图片的移除
    handleRemove(file, fileList) {
      this.avatar.map((item, index) => {//移除图片在表单中的数据
        if (item === file.response.data) this.avatar.splice(index, 1)
      })

    },
    //更新题目中的上传图片的移除
    handleUpdateRemove(file, fileList) {
      this.addUserForm.images.map((item, index) => {//移除图片在表单中的数据
        if (item === file.response.data) this.addUserForm.images.splice(index, 1)
      })
    },
    //新增题目中的上传图片的格式大小限制
    beforeAvatarUpload(file) {
      const isImg = file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      const isLt = file.size / 1024 / 1024 < 10

      if (!isImg) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!')
      }

      if (!isLt) {
        this.$message.error('上传头像图片大小不能超过 10MB!')
      }
      return isImg && isLt
    },
    //新增题目中的上传图片成功后的钩子函数
    uploadImgSuccess(response, file, fileList) {
      this.avatar.push(response.data)
      const x = this.avatar.join(',')
      this.addUserForm.avatar = x;

    },
    updateuploadImgSuccess(response, file, fileList) {
      this.avatar.push(response.data)
      const x = this.avatar.join(',')
      this.updateUserForm.avatar = x;
    },
    //答案上传照片了
    uploadCardImgSuccess(response, file, fileList) {
      this.cardAvator.push(response.data)
      const x = this.cardAvator.join(',')
      this.addUserForm.cardAvator = x;

    },
    //答案上传成功后删除
    handleCardRemove(file, fileList) {
      this.cardAvator.map((item, index) => {//移除图片在表单中的数据
        if (item === file.response.data) this.cardAvator.splice(index, 1)
      })
    },

    handleChisRemove(file, fileList) {

      this.chsiAvatar.map((item, index) => {//移除图片在表单中的数据
        if (item === file.response.data) this.chsiAvatar.splice(index, 1)
      })
    },
    updateuploadCardImgSuccess() {
      this.cardAvator.push(response.data)
      const x = this.cardAvator.join(',')
      this.updateUserForm.cardAvator = x;
    },
    uploadChisImgSuccess(response, file, fileList) {
      this.chsiAvatar.push(response.data)
      const x = this.cardAvator.join(',')
      this.addUserForm.chsiAvatar = x;

    },
    updateuploadChisImgSuccess(response, file, fileList) {
      this.chsiAvatar.push(response.data)
      const x = this.cardAvator.join(',')
      this.updateUserForm.chsiAvatar = x;

    },

    handleCerRemove(file, fileList) {
      this.cerAvatar.map((item, index) => {//移除图片在表单中的数据
        if (item === file.response.data) this.cerAvatar.splice(index, 1)
      })

    },
    uploadCerImgSuccess(response, file, fileList) {
      this.cerAvatar.push(response.data)
      const x = this.cerAvatar.join(',')
      this.addUserForm.cerAvatar = x;


    },
    updateuploadCerImgSuccess(response, file, fileList) {
      this.cerAvatar.push(response.data)
      const x = this.cerAvatar.join(',')
      this.updateUserForm.cerAvatar = x;


    },
    //更新题目中的上传图片成功后的钩子函数
    updateUploadImgSuccess(response, file, fileList) {
      this.avatar.push(response.data)
    },
    chagnge() {
      this.addUserVisible = false
      this.allDept = null
      this.allCoal = null
      this.allDept = null
      this.subject = null

    },
    updateChange() {
      this.updateQuTableVisible = false
      this.allDept = null
      this.allCoal = null
      this.allDept = null
      this.subject = null
    },
    shenhe(){
      this.$refs['shenhe'].validate((valid)=>{
        if (valid) {
          this.remarkForm.infoNo = this.checkUserForm.infoNo
         // console.log(this.remarkForm.infoNo)
          question.shenhe(this.remarkForm).then((resp) => {
            if (resp.code === 200) {
              this.shenheTableVisible = false

              this.getUserInfo()
              this.$notify({
                title: 'Tips',
                message: '审核成功',
                type: 'success',
                duration: 2000
              })
            }
          })
        } else if (!valid) {
          this.$message.error('请填写必要信息')
          return false
        }
      })
    },
    butongguo(remark){
      this.shenheTableVisible = true
      if(remark !== ''){
        this.remarkForm.remark = remark
      }else {
        this.remarkForm.remark=''
      }

      this.checkUserVisible = false
    },
    shenheT(){
      this.remarkForm.infoNo = this.checkUserForm.infoNo
      this.remarkForm.remark = ''
      question.shenhe(this.remarkForm).then((resp) => {
        if (resp.code === 200) {
          this.checkUserVisible = false

          this.getUserInfo()
          this.$notify({
            title: 'Tips',
            message: '审核成功',
            type: 'success',
            duration: 2000
          })
        }
      })
    },
    //新增用户
    addUser() {
      this.$refs['addUserForm'].validate((valid) => {
       // console.log(this.addUserForm)
        this.allDept = null
        this.allCoal = null
        this.allDept = null
        this.subject = null
        if (valid) {//单选或者多选或者判断
          question.addUser(this.addUserForm).then((resp) => {
            if (resp.code === 200) {
              this.addUserVisible = false

              this.getUserInfo()
              this.$notify({
                title: 'Tips',
                message: '新增用户成功',
                type: 'success',
                duration: 2000
              })
            }
          })
        } else if (!valid) {
          this.$message.error('请填写必要信息')
          return false
        }
      })
    },


    //更新题目
    updateQu(id) {
      question.getQuestionById(id).then((resp) => {
        if (resp.code === 200) {
          if (resp.data.questionType !== 4) {
            resp.data.answer.map(item => {
              item.isTrue = item.isTrue === 'true'
            })
          }
          this.updateQuForm = resp.data
          //处理图片那个参数是个数组
          if (this.updateQuForm.images === null) this.updateQuForm.images = []

          if (resp.data.questionType !== 4) {
            this.updateQuForm.answer.map(item => {
              if (item.images === null) {
                item.images = []
              }
            })
          }

        }
      })
      this.updateQuTableVisible = true
    },


    //提交更新表单
    updateUserestion() {
      this.allDept = null
      this.allCoal = null
      this.allDept = null
      this.subject = null
      this.$refs['updateUserForm'].validate((valid) => {
        if (valid) {
          question.updateUserestion(this.updateUserForm).then((resp) => {
            if (resp.code === 200 && resp.data === '更新成功') {
              this.updateQuTableVisible = false
              this.getUserInfo()
              this.$notify({
                title: 'Tips',
                message: resp.data,
                type: 'success',
                duration: 2000
              })
            }
            else {
              this.$message.error(resp.data)
            }
          })
        } else if (!valid) {
          this.$message.error('请填写必要信息')
          return false
        }
      })
    },
  },
    computed: {
      //监测头部信息的token变化
      headers() {
        const signHeaders = {
          'body-string': '',
          'query-string': '',
          'x-nonce': `${utils.getRandomId()}`,
          'x-timestamp': `${new Date().getTime()}`
        }
        return {
          ...signHeaders,
          authorization: localStorage.getItem('authorization') || '',
          sign: generateSign(JSON.stringify(signHeaders)),
        }
      },
    }

}
</script>

<style scoped lang="scss">
@import "../../assets/css/teacher/questionManage";
</style>

<style>
.el-icon-view{
  cursor: pointer; /* Change the mouse style to pointer (hand) */
  color: red;
}
.custom-alert:hover {
  cursor: pointer;
}
</style>
